// @import '../../utils/variables';

.slidersukcesu.slidersukcesu--fade {
	background: #370023;

	$sliderheight: calc(100vh - 15.3rem);
	$sliderheight-small: calc(100vh - 12.7rem);
	.carousel-item {
		height: $sliderheight;
		@media (max-width: 1199.98px) {
			height: $sliderheight-small;
		}
		transition: 1s;

		img {
			@media (max-width: 1199.98px) {
				min-height: $sliderheight-small;
			}
			min-height: $sliderheight;
			min-width: 100vw;
			width: auto;
			margin-left: 50%;
    		transform: translateX(-50%);
			opacity: 0.61;
		}
	}

	.white-line {
		width: 30rem;
		margin: 3rem 0 3rem auto;
	}

	.carousel-caption {
		left: 0;
		right: 0;
		top: -75%;
		transform: translateY(50%);

		@media (max-height: 800px) and (min-width: 1200px) {
			top: -90%;
		}

	}

	.slide {
		&__body {
			p {
				color: #fff;
				width: 40%;
				@media (max-width: 1199.98px) {
					width: 65%;
				}
				@media (max-width: 991.98px) {
					width: 75%;
				}
				margin-left: auto;
			}
		}


		&__title {
			color: #fff;
			margin-bottom: 3rem;
		}

		&__controls {
			display: flex;
			position: absolute;
			top: 70%;
			left: 50%;
			transform: translateX(-50%);
			justify-content: flex-end;
			z-index: 80;

			@media (max-height: 800px) and (min-width: 1200px) {
				top: 80%;
			}

			@media (max-width: 1199.98px) and (orientation: landscape) {
				top: 80%
			}
		}

		&__button {
			@media (max-height: 800px) and (min-width: 1200px) {
				margin-top: 3rem;
			}

			margin-top: 6rem;
		}
	}

	.carousel-indicators {
		display: flex;
		align-items: center;
		position: relative;
		margin: 2rem 0;

		li {
			height: 1rem;
			width: 1rem;
			border: none;
			border-radius: 50%;
			opacity: 1;
			transition: 0.3s ease;
			margin: 0 .8rem;
		}

		.active {
			height: 1.6rem;
			width: 1.6rem;
			background: #8C0259;
		}
	}

	.carousel-control-prev,
	.carousel-control-next {
		width: 3.5rem;
		height: 6.5rem;
		position: relative;

		&:hover {
			color: #fff;
		}

		.material-icons {
			width: 6.5rem;
		}
	}

	.carousel-control-prev {
		justify-content: flex-end;
	}

	.carousel-control-next {
		justify-content: flex-start;
	}

}

@media (min-width: 576px) {

	.carousel-control-prev,
	.carousel-control-next {
		width: calc((100% - 54rem)/2 + 6.5rem);
	}
}

@media (min-width: 768px) {

	.carousel-control-prev,
	.carousel-control-next {
		width: calc((100% - 72rem)/2 + 6.5rem);
	}
}

@media (min-width: 992px) {

	.carousel-control-prev,
	.carousel-control-next {
		width: calc((100% - 96rem)/2 + 6.5rem);
	}
}

@media (min-width: 1200px) {

	.carousel-control-prev,
	.carousel-control-next {
		width: calc((100% - 114rem)/2 + 6.5rem);
	}
}
